<template>
    <div
        class="flex justify-center items-center h-12 w-12 ml-2 rounded-full bg-green-400
        text-white shadow-md transition hover:bg-green-500 cursor-pointer"
        @click="showMonthPicker"
    >
        <p>
            <fa-icon v-if="$wait.is('loading.calendar')" :icon="['fad','spinner']" spin class="text-xl" />

            <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8 9C7.44772 9 7 9.44771 7 10C7 10.5523 7.44772 11 8 11H16C16.5523 11 17 10.5523 17 10C17 9.44771 16.5523 9 16 9H8Z"
                    fill="currentColor"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6 3C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V6C21
                    4.34315 19.6569 3 18 3H6ZM5 18V7H19V18C19 18.5523 18.5523 19 18 19H6C5.44772 19 5 18.5523 5 18Z"
                    fill="currentColor"
                />
            </svg>
        </p>
        <div class="flex absolute w-4 justify-center">
            <el-date-picker
                ref="monthPicker"
                v-model="month"
                :clearable="false"
                type="month"
                class="center-arrow"
                @change="monthChange"
            />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            monthPickerVisible: false,
            month:              null,
        };
    },

    methods: {
        showMonthPicker() {
            if (this.monthPickerVisible) {
                this.monthPickerVisible = false;
                return;
            }
            this.monthPickerVisible = true;
            this.$refs.monthPicker.focus();
        },

        monthChange() {
            this.$emit('monthChange', this.month);
            this.monthPickerVisible = false;
        },
    },
};
</script>

<style scoped>
    .el-date-editor .el-input__prefix {
        display: none !important;
    }

    .center-arrow{
        left: -35px;
        position: relative;
    }

</style>
<style>
    .month-floating-menu .el-date-editor .el-input__prefix {
        display: none;
    }
    .month-floating-menu .el-date-editor .el-input__inner{
        padding: 0;
        cursor: pointer;
        opacity: 0;
    }
</style>
